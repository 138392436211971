@import ../_vars

.deckcard
  background: #000
  width: 100%
  height: 132px
  display: block
  text-decoration: none
  overflow: hidden
  position: relative
  &__illustration
    position: absolute
    left: 50%
    transform: translate3d(-50%,15%,0) scale(1.4,1.4)
    z-index: $z-deckindex-illustration
  &__content
    $margin: 6px
    z-index: $z-deckindex-content
    position: absolute
    top: $margin
    left: $margin
    right: $margin
    bottom: $margin
  &__name
    font-size: 18px
    line-height: 24px
    color: #FFF
  &__game-mode
    $size: 36px
    position: absolute
    top: 0
    right: 0
    height: $size
    width: $size
    text-align: center
    line-height: $size
    border-radius: 100%
    background: var(--color-stamp-bg)
    font-size: 13px
    color: var(--color-stamp-text)

  &__subtitle
    font-size: 15px
    line-height: 24px
    color: #FFF
  &__clans
    display: flex
    margin-top: 6px
  &__clan
    display: block
    width: 24px
    height: 24px
    margin-left: 8px
    &:first-child
      margin-left: 0
  &__summary
    font-size: 15px
    line-height: 15px
    color: #FFF
    text-align: center
    position: absolute
    bottom: 2px
    left: 0
    right: 0
  &__summary-item
    white-space: nowrap

.char-bg
  overflow: hidden
  position: relative
  width: 240px
  height: 195px
  &__img
    position: absolute
    top: -72px
    left: -58px
    z-index: 1
  &__mask
    position: absolute
    height: 100%
    width: 100%
    z-index: 2
    background: linear-gradient(90deg, rgba(0,0,0,1) 1%, rgba(0,0,0,0.4) 20%, rgba(0,0,0,0.4) 80%, rgba(0,0,0,1) 99%)
