@import ../_vars

.toptabs
  display: grid
  grid-template-columns: repeat(2,1fr)
  &__tab
    padding: $space-small $space-medium
    text-align: center
    cursor: pointer
    border: 2px solid var(--color-bg-farthest)
    background: var(--color-bg-far)
    &--active
      background: var(--color-bg-farthest)
