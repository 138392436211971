
@import ../../_vars

.page
  align-content: stretch
  display: grid
  justify-content: stretch
  min-height: 100vh
  grid-template-rows: $header-height auto 210px
  @media screen and ( min-width: $breakpoint-ipad--vertical)
    grid-template-rows: $header-height auto 150px

  .page-header
    grid-row: 1 / 2
    background: var(--color-bg-farthest)
    padding: 0 $safearea-margin

  .page-content
    grid-row: 2 / 3
    padding: 0 $safearea-margin

  .page-footer
    grid-row: 3 / 4
    background: var(--color-bg-farthest)
    padding: $space-large $safearea-margin $space-small

  .page-header
    border-bottom: 6px solid #0057b7

  .page-content
    border-top: 6px solid #ffd700
