@import ../_vars

.page-decks
  &__content
    margin-top: $space-medium

  &__decks
    margin: $space-medium 0 $space-huge
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(340px,1fr))
    gap: $space-medium

.deck-index-filters
  margin-top: $space-small
  &__filter
    display: inline-block
    margin-left: $space-small

    label
      display: none
