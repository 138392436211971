@import ../_vars

.username
  display: inline-block
  &__patronage,
  &__name
    vertical-align: middle
    display: inline-block
  &__patronage
    $size: 18px
    height: $size
    width: $size
    margin-right: $space-nano
