@import ../_vars

.page-about
  &__content
    margin-top: $space-medium
    display: flex
    flex-direction: column
    align-items: center
  &__attributions
    margin-top: $space-small
    flex: 1 1 auto
    max-width: 600px
    width: 100%

.attribution
  &-item
    display: flex
    padding-top: $space-micro
    &:first-child
      padding-top: 0
  &-icon
    display: inline-block
    height: 24px
    padding-right: $space-small
    width: 24px
  &-list
    margin-bottom: $space-small
    margin-top: $space-small