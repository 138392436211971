.svg-icon
  align-items: center
  display: flex
  height: 100%
  width: 100%

  &__foreground
    &--standard
      fill: var(--color-icon-standard)

    &--negative
      fill: var(--color-icon-negative)

  &__background
    &--standard
      fill: var(--color-icon-standard-bg)

    &--negative
      fill: var(--color-icon-negative-bg)
