.attribute
  $size: 30px
  display: inline-block
  width: $size
  height: $size
  line-height: $size
  text-align: center
  position: relative

  &__icon
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: -1
    opacity: 0.8

  &__number
    font-size: 21px
  &-number--light
    color: #FFF
  &-number--dark
    color: #000
