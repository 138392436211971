@import ../_vars

.search
  &-group
    margin-top: $space-small
  &__cardpool
    display: inline-block
  &__pack
    display: inline-block

.filter-group
  display: flex
  flex-wrap: wrap
  overflow: hidden
  margin-top: $space-medium
  gap: $space-micro

.searchresults
  margin-top: $space-medium
  display: grid
  gap: $space-large
  place-items: start center
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))

.searchpage
  &__filters
    margin-top: $space-large
  &__results
    margin-top: $space-large
