.ui-icon
  width: 100%
  height: 100%
  -webkit-mask-size: contain
  -webkit-mask-position: center center
  -webkit-mask-repeat: no-repeat
  mask-size: contain
  mask-position: center center
  mask-repeat: no-repeat
  display: inline-block
  &--negative
    background-color: var(--color-icon-negative)
  &--standard
    background-color: var(--color-icon-standard)
  span
    visibility: hidden

$other_icons: action agenda alchemy animal arsenal attack blood bloodpotencyrequirement city city-conclave city-core city-hoe city-hah conspiracy crown damage delete dronejockey edit faction fleet form gift ghoul hand_of_cards haven influence influence_modifier leader library mental menu monster ongoing ordnance physical ranged reaction relic rite ritual save scheme shield skull social special title trap unhosted_action vehicle
@each $icon in $other_icons
  .ui-icon_#{$icon}
    --svg-ui-icon: url("data-url:../../assets/icons/icon-#{$icon}.svg")
    -webkit-mask-image: var(--svg-ui-icon)
    mask-image: var(--svg-ui-icon)

$bugged_when_inlined: bloodpotency
@each $icon in $bugged_when_inlined
  .ui-icon_#{$icon}
    -webkit-mask-image: url("../../assets/icons/icon-#{$icon}.svg")
    mask-image: url("../../assets/icons/icon-#{$icon}.svg")

.ui-icon--colored
  &.ui-icon_bloodpotency
    background-color: var(--color-blood)
  &.ui-icon_physical
    background-color: var(--color-physical)
  &.ui-icon_social
    background-color: var(--color-social)
  &.ui-icon_mental
    background-color: var(--color-mental)
  &.ui-icon_bloodpotencyrequirement
    background-color: var(--color-bloodreq)
