$breakpoint-ipad--vertical: 768px
$breakpoint-ipad--horizontal: 1024px
$breakpoint-largest-width: 1700px

$safearea-margin: 16px

$round-border: 3px

$z-deckbuilder-actions: 1
$z-deckindex-illustration: 1
$z-deckindex-content: 3
$z-card-name-image: 10
$z-burgernav: 11
$z-modal-wrapper: 20
$z-toast: 25
$z-modal-content: 30

$font-size--header: 28px
$font-line-height-std: 24px
$font-size--subheader: 21px
$font-size--sectionheader: 18px
$font-size--body: 15px
$font-size--discreet: 14px
$font-size--small: 12px

$space-nano: 4px
$space-micro: 8px
$space-small: 12px
$space-medium: 16px
$space-large: 24px
$space-huge: 32px

$header-height: 40px
