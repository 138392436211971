@import ../../../_vars

.deckview__aside
  nav
    margin-bottom: $space-large

.deckview__description
  padding-bottom: $space-huge

.deckview__drawtrigger
  margin-bottom: $space-medium
