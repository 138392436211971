.filterpicker
  border-color: var(--color-copy-darker)
  border-radius: .5rem
  display: flex
  flex-wrap: wrap

  $optionInner: 32px

  &__option
    display: inline-block
    padding: 2px
    height: $optionInner
    width: $optionInner
    cursor: pointer
    text-align: center
    background: var(--color-bg-farthest)

    &--active
      background: var(--color-bg-closest)

  &__checkbox
    display: none

  &__box
    $padding: 4px
    $size: $optionInner - (2 * $padding)
    height: $size
    width: $size
    padding: $padding
    display: flex
    justify-content: center
    align-items: center
    background: var(--color-bg-closest)

    &--active
      background: var(--color-bg-farthest)

  legend
    color: var(--color-copy-highlight)
