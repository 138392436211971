@import ../_vars

.decklist
  $spaceToFooter: $space-huge + $space-medium
  display: grid
  gap: 0 $space-small
  grid-template-columns: repeat(6, 1fr)

  &__title
    grid-column: 1 / 7

  &__core
    grid-column: 1 / 7
  &__core-image
    display: none

  &__faction,
  &__library
    grid-column: 1 / 7
    margin-top: $space-medium
  &__library
    margin-bottom: $spaceToFooter
  @media screen and ( min-width: $breakpoint-ipad--horizontal)
    &__core-image
      display: block
    &__core-heading
      display: none

    &__agenda
      grid-row: 3 / 4
      grid-column: 3 / 5
    &__leader
      grid-row: 3 / 4
      grid-column: 1 / 3
    &__haven
      grid-row: 3 / 4
      grid-column: 5 / 7

    &__faction,
    &__library
      grid-row: 4 / 5
      margin-top: $space-large
      margin-bottom: $spaceToFooter
    &__faction
      grid-column: 1 / 4
    &__library
      grid-column: 4 / 7

  &__title
    margin-bottom: $space-medium

  &__title-name
    display: inline-block
  &__title-action
    display: inline-block
    cursor: pointer
    color: var(--color-link)
    text-decoration: underline
    user-select: none
    margin-left: $space-micro

.decklist
  &__meta
    display: flex
    gap: $space-micro
  &__details
    font-size: $font-size--body
    font-style: italic
    color: var(--color-copy-highlight)

.decklist
  &--section-header
    font-size: $font-size--sectionheader
    line-height: $font-line-height-std
  &--subsection-header
    font-size: $font-size--body
    line-height: $font-line-height-std

.deck-library
  &__header
    margin-bottom: $space-small
    &--invalid
      color: var(--color-warning-copy)
  &__section-header
    margin-top: $space-small
    &:first-child
      margin-top: 0
  &__entry
    margin-top: $space-nano
    display: flex
    align-items: center
    &:first-child
      margin-top: 0
  &__entry-count,
  &__entry-times
    display: inline-block
    width: 12px
    text-align: center
  &__entry-picker
    display: inline-block
    margin-right: $space-micro
  &__entry-clan
    $iconSize: 24px
    display: inline-block
    margin-left: $space-nano
    width: $iconSize
    height: $iconSize

.deck-faction
  &__header
    margin-bottom: $space-small
    display: flex
    &--invalid
      color: var(--color-warning-copy)
  &__title
    display: inline-block
    margin-right: $space-micro
  &__clan-list
    display: flex
  &__clan-item
    $iconSize: 24px
    display: inline-block
    margin-left: $space-nano
    width: $iconSize
    height: $iconSize
  &__character
    margin-top: $space-micro
    display: flex
    align-items: center
    &:first-child
      margin-top: 0
    &--leader
      font-weight: bold
  &__leader-tag
    display: inline-block
    margin-left: $space-micro
  &__leader-button
    $size: 30px
    width: $size
    height: $size
    border-radius: $size
    cursor: pointer
    margin-right: $space-micro
    .deck-faction__leader-icon
      width: 100%
      height: 100%
    &--on
      background: var(--color-bg-farthest)
      transition: 0.2s
    &--off
      background: var(--color-bg-closest)
      .deck-faction__leader-icon
        opacity: 0.4
        transition: 0.3s ease
      &:hover
        background: var(--color-bg-close)
        .deck-faction__leader-icon
          opacity: 1

  &__bp,
  &__clan,
  &__name
    display: inline-block
  &__name
    margin-left: $space-micro

  &__name,
  &__leader-tag
    line-height: $font-line-height-std
    font-size: $font-size--body

  &__clan,
  &__discipline
    $iconSize: 24px
    width: $iconSize
    height: $iconSize

  &__clan
    margin-left: $space-micro

  &__discipline
    margin-left: $space-nano
  &__remove
    $size: 21px
    all: unset
    margin-left: $space-small
    background: var(--color-bg-closest)
    border-radius: 100%
    height: $size
    width: $size
    line-height: $size
    font-weight: normal
    display: inline-block
    text-align: center
    cursor: pointer
    user-select: none
