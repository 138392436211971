@import ../_vars

.profile__header
  margin-top: $space-medium
  margin-bottom: $space-medium
.profile__item
  input
    margin-left: $space-small
.profile__item,
.profile__save
  display: block
.profile__save
  margin-top: $space-small
  text-align: center
