@import ../_vars

.page-my-decks
  &__content
    margin-top: $space-medium

  &__decks
    margin: $space-medium 0 $space-huge
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(340px,1fr))
    gap: $space-medium
