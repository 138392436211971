@import ../../_vars

.tsplit
  display: grid
  gap: $space-small
  grid-template-columns: repeat(1, 1fr)
  &__bar
    grid-column: 1 / 2
    grid-row: 1 / 2
  &__main
    grid-column: 1 / 2
    grid-row: 2 / 3
  &__secondary
    grid-column: 1 / 2
    grid-row: 3 / 4

@media screen and ( min-width: $breakpoint-ipad--horizontal)
  .tsplit
    gap: $space-medium $space-large
    grid-template-columns: repeat(2, 1fr)

    &__bar
      grid-column: 1 / 3

    &__main
      grid-column: 1 / 2
      grid-row: 2 / 3
    &__secondary
      grid-column: 2 / 3
      grid-row: 2/3

.tsplit__bar
  background: var(--color-bg-close)
  margin: 0 ($safearea-margin * -1)
  height: 48px
  padding: $space-small $safearea-margin
  text-align: center

  @media screen and ( min-width: $breakpoint-ipad--horizontal)
    position: sticky
    top: 0
    z-index: $z-deckbuilder-actions

// SEARCH PAGE USES THIS
.deckbldr
  &-flaggroup
    display: inline-block
    margin-bottom: 8px

.cardlist
  &__row
    padding: 4px 0
    border-top: 1px solid var(--color-divider)
    display: flex
    flex-wrap: nowrap
    gap: 0 12px
    &:first-child
      border-top: none

  &__quant--row
    flex: 0 0 auto

  &__name
    flex: 1 1 auto

  &__props
    flex: 0 0 auto
    display: flex
    align-items: center
    height: 32px
    gap: 0 4px

  &__clan
    $size: 21px
    width: $size
    height: $size

  &__extra-prop
    display: none
    @media screen and ( min-width: $breakpoint-ipad--horizontal)
      &
        display: inline-block

.char-icons-list
  $gap: 3px
  $itemSize: 20px
  display: flex
  gap: $gap
  width: (4 * $itemSize) + (2 * $gap)
  &__item
    display: inline-block
    width: $itemSize
    height: $itemSize
