@use "sass:math"

.cardimage
  $cardHeight: 418px
  $cardWidth: 300px
  $aspectRatio: math.div($cardHeight, $cardWidth) * 100%

  max-width: $cardWidth
  max-height: $cardHeight
  width: 100%
  user-select: none
  &::before
    content: ""
    width: 1px
    margin-left: -1px
    float: left
    height: 0
    padding-top: $aspectRatio

  &::after
    content: ""
    display: table
    clear: both

  img
    border-radius: 4.33%/3.1%
    height: auto
    object-fit: contain
