@import ../_vars

.page-home
  &__content
    margin-top: $space-medium
    display: flex
    flex-direction: column
    align-items: center
  &__announcements
    margin-top: $space-small
    flex: 1 1 auto
    max-width: 600px
    width: 100%

.announcements
  &__date
    font-weight: bold
    margin-top: $space-micro
    padding-top: $space-micro
    border-top: 1px solid var(--color-divider)
    &:first-child
      margin-top: 0
      padding-top: 0
      border-top: none
  &__txt
    margin: 0
