@import ../../_vars

.header
  display: flex
  overflow: none

  &__nav
    flex: 1 0 auto
    margin-right: -1 * $safearea-margin
    @media screen and ( min-width: $breakpoint-ipad--vertical)
      margin-right: 0

  &__search
    display: none
    flex: 0 0 auto

    @media screen and ( min-width: $breakpoint-ipad--vertical)
      display: block
      width: 120px

    input
      width: 100%

.logo
  $smalLogoSize: 20px
  $largeLogoSize: 83px
  flex: 0 1 auto
  width: $smalLogoSize
  @media screen and ( min-width: $breakpoint-ipad--vertical)
    width: $largeLogoSize
  &__img
    --svg-rivalsdb-ico: url("data-url:../../assets/logo/rivalsdb-ico.svg") no-repeat center center / contain

    width: 100%
    height: 100%
    display: block
    overflow: hidden
    background-color: var(--color-header-logo)
    -webkit-mask: var(--svg-rivalsdb-ico)
    mask: var(--svg-rivalsdb-ico)
    @media screen and ( min-width: $breakpoint-ipad--vertical)
      --svg-rivalsdb-logo: url("data-url:../../assets/logo/rivalsdb-logo.svg")
      -webkit-mask-image: var(--svg-rivalsdb-logo)
      mask-image: var(--svg-rivalsdb-logo)

  &__txt
    display: none

.nav
  display: flex
  gap: 0 $space-small
  justify-content: flex-end
  align-items: center

  &__item
    &--main
      display: inline-block
    &--ext
      display: none
      @media screen and ( min-width: $breakpoint-ipad--vertical)
        display: inline-block
  &__burger
    height: 100%
    @media screen and ( min-width: $breakpoint-ipad--vertical)
      display: none

.burger
  background: none
  border: 0
  border-radius: 0
  width: $header-height
  height: $header-height
  padding: 10px 8px 6px
  &::-moz-focus-inner
    border: 0
    padding: 0
  &__icon
    width: 100%
    height: 100%

.burgernav
  $width: 250px
  position: absolute
  z-index: $z-burgernav
  top: $header-height
  right: 0
  bottom: 0
  width: $width
  overflow: hidden
  pointer-events: none

  .burgernav__content
    background: var(--color-bg-farthest)
    height: 100%
    padding: $space-medium $space-small
    transition: transform 0.4s ease-in-out
    transform: translate3d($width, 0, 0)
    pointer-events: all

  &--open
    .burgernav__content
      transform: translate3d(0, 0, 0)

  &__link
    text-decoration: none

  &__item
    padding: $space-micro $space-small
    border-top: 1px solid var(--color-divider)
    &:first-child
      border-top: none
      padding-top: 0
    &--emphasis
      padding: $space-medium $space-small
