body
  --font-size--header: 20px
  --font-size--default: 16px
  --font-size--small: 14px
  --font-size--micro: 11px

  --color-icon-standard: #E5D195
  --color-icon-standard-bg: #2f5678
  --color-icon-negative: #FFF
  --color-icon-negative-bg: #000

  --color-bg-modalcover: rgba(15, 27, 38, 0.8)
  --color-bg-modalshadow: rgba(0, 0, 0, 0.9)
  --color-bg-farthest: rgba(15, 27, 38, 1)
  --color-bg-far: rgba(28, 51, 71, 1)
  --color-bg-close: rgba(38, 70, 97, 1)
  --color-bg-closest: rgba(47, 86, 120, 1)
  --color-bg-warning: #993926
  --color-stamp-bg: rgba(47, 86, 120, 0.5)
  --color-stamp-text: #FFF
  --color-copy-darker: rgba(229, 209, 149, 0.5)
  --color-copy-medium: rgba(229, 209, 149, 1)
  --color-copy-highlight: #F5EDD6
  --color-link: rgba(87, 189, 37, 1)
  --color-footer-text: rgba(36,106,120,1)
  --color-header-logo: rgba(37,163,189,1)
  --color-divider: rgba(37,163,189, 0.2)
  --color-transparent-lighter: rgba(255,255,255,0.1)
  --color-transparent-shade: rgba(0,0,0,0.2)
  --color-warning-copy: #BD3E25
  --color-cancel: #BD3E25
  --color-blood: #AD231F
  --color-bloodreq: #5C6D70
  --color-physical: #2959A3
  --color-social: #A33829
  --color-mental: #A37A29
  --color-ranged: #8FA0A3

body
  background: var(--color-bg-far)
  color: var(--color-copy-medium)
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"
  font-size: var(--font-size--default)
  line-height: 24px
  margin: 0
  text-rendering: optimizelegibility

a
  color: var(--color-link)

ul,ol,h1,h2,h3,h4,h5,h6,p
  margin: 0
  padding: 0
li
  list-style: none

h1,h2
  font-size: 1rem
